<template>
    <div :class="$style.codeVerify">
        <div :class="$style.verify_bar">
            <div :class="$style.tips">
                <div :class="$style.icon">
                    <pt-icon
                        v-if="isActived"
                        icon="pt-icon--tick"
                        key="success"
                        :iconStyle="{ width: '24px', height: '24px' }"
                    ></pt-icon>
                    <pt-icon
                        v-else-if="checkClicked"
                        icon="pt-icon--warn"
                        key="fail"
                        :iconStyle="{ width: '24px', height: '24px' }"
                    ></pt-icon>
                </div>
                <p
                    :class="[checkStatus === 'success' && $style.success, checkStatus === 'fail' && $style.fail]"
                    v-html="tipText"
                ></p>
            </div>

            <pt-button
                color="blue"
                type="outline"
                :class="$style.btn_left"
                @click="checkProfileStatus"
                v-if="!isActived"
                >{{ $t('common.button_check') }}</pt-button
            >
        </div>
        <div :class="$style.faq">
            <div v-for="(faq, index) in faqList" :key="'faq_' + index" :class="$style.faq_item">
                <div :class="$style.title">{{ faq.title }}</div>
                <div :class="$style.desc" v-html="faq.desc"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import accountApis from '@/apis/account.apis';
import docsUrl from '@common/configs/docsUrl';

export default {
    name: 'CodeVerify',
    data() {
        return {
            faqList: [
                {
                    title: this.$t('data_settings.install_verify_q1'),
                    desc: this.$t('data_settings.install_verify_q1_desc', { href: docsUrl.faqTrackingMark })
                },
                {
                    title: this.$t('data_settings.install_verify_q2'),
                    desc: this.$t('data_settings.install_verify_q2_desc')
                }
            ],
            checkClicked: false
        };
    },
    computed: {
        ...mapGetters('main', ['profileInfo']),
        isActived() {
            return this.profileInfo.isActived === 1;
        },
        checkStatus() {
            if (this.isActived) {
                return 'success';
            } else if (!this.checkClicked) {
                return 'uncheck';
            } else {
                return 'fail';
            }
        },
        tipText() {
            if (this.isActived) {
                return this.$t('data_settings.install_check_status_success');
            } else if (this.checkClicked) {
                return this.$t('data_settings.install_check_status_fail');
            } else {
                return this.$t('data_settings.install_check_status_default');
            }
        }
    },
    methods: {
        ...mapMutations('main', ['UPDATE_PROFILE_INFO']),
        checkProfileStatus() {
            this.checkClicked = true;
            accountApis
                .checkActiveProfile(null, {
                    where: {
                        sid: this.profileInfo.sid
                    }
                })
                .then(
                    res => {
                        if (res) {
                            const profileInfo = Object.assign({}, this.profileInfo, { isActived: 1 });
                            this.UPDATE_PROFILE_INFO(profileInfo);
                        }
                    },
                    err => {}
                );
        },
        gotoHome() {
            this.$router.push({ name: 'Users' });
        }
    }
};
</script>
<style lang="scss" module>
@import '@/styles/import.scss';
.codeVerify {
    width: 650px;
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
    .verify_bar {
        margin: 110px auto 40px;
        height: 100px;
        border: 1px solid $pt-black-40;
        padding: 28px 40px;
        display: flex;
        width: 600px;
        align-items: center;
        background-color: $pt-white;

        .tips {
            color: $pt-black-600;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 22px;
            margin-right: 60px;
            display: flex;
            align-items: center;
            flex: 1;
            .icon {
                margin-right: 12px;
            }
            .success {
                color: $pt-success;
            }
            .fail {
                color: $pt-danger;
            }
        }
    }
    .faq {
        display: flex;
        flex-flow: row;
        flex-grow: 1;
        .faq_item {
            margin: 0 20px;
            max-width: 280px;
            .title {
                color: $pt-black-600;
                font-size: 14px;
                line-height: 20px;
                height: 20px;
                font-weight: 600;
                text-align: center;
            }
            .desc {
                margin-top: 8px;
                height: 48px;

                color: $pt-black-300;

                font-size: 12px;
                letter-spacing: 0;
                line-height: 16px;
                text-align: center;
                :global(.solution) {
                    color: $pt-black-600;
                    text-decoration: underline;
                    &:visited {
                        color: $pt-black-600;
                    }
                    &:hover,
                    &:active {
                        color: $pt-black-1000;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
</style>
